import React from "react";
import { Components } from ".";
const Modals: React.FC<any> = ({ component, isOpen, onClose, data }) => {
  return component ? (
    React.createElement(Components[component], {
      isOpen,
      onClose,
      data,
    })
  ) : (
    <></>
  );
};

export default Modals;
