import { format, parseISO } from "date-fns";

export const pathFix = (path: string) => {
  return path + (path.endsWith("/") ? "" : "/");
};

export const titleCase = (str: any) => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
};

export const formatDate = (value: string) => {
  const date = new Date(value);
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return format(parseISO(value), "MMMM dd, yyyy");
  } else {
    return "";
  }
};
/***
 var date = moment()
      .utcOffset('+05:30')
      .format('YYYY-MM-DD hh:mm:ss a');
 */
export const formatDateTime = (
  value: any,
  formatString: string = "MMMM dd, yyyy hh:mm aaaaa'm'"
) => {
  const date = new Date(value);
  if (date instanceof Date && !isNaN(date.valueOf())) { 
    // return format(date, "dd MMM, hh:mm aaaaa'm'")
    return format(date, formatString)
  } else {
    return "";
  }
};

export const numberOnlyValidation = (event: any) => {
  const pattern = /[0-9.,]/;
  let inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
    // invalid character, prevent input
    event.preventDefault();
  }
};

export const currencyFormat = (
  num: any,
  currency: string = "$",
  decimals: number = 2
) => {
  if (!num) {
    num = 0;
  }
  return (
    currency + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, `1,`)
  );
};
