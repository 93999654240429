import React, { useState } from "react";
import { IonCard, IonCardContent, IonContent } from "@ionic/react";
import AtListItem from "../common/AtListItem";
import AtSearchBar from "../common/AtSearchBar";
 

const data = [
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: "19 Dec",
    subtitle: "Camiling, Tarlac, Philippines",
    subtitle2: "11:30 AM",
    remarks: "Interior Decorations",
    remarks2: "", 
    subremarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssItem: "at-border-primary",
    cssTitle2: "color-primary",

    cost: "₱100 per hour",
    address: "Camiling, Tarlac, Philippines",
    latlong: "",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: "28 Feb",
    subtitle: "Iguig, Cagayan Valley, Philippines",
    subtitle2: "10:25 AM",
    remarks: "Bathroom fittings",
    remarks2: "",
    subremarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱200 per hour",
    address: "Iguig, Cagayan Valley, Philippines",
    latlong: "",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: "28 Feb",
    subtitle: "San Jose, Antipolo, Philippines",
    subtitle2: "10:25 AM",
    remarks: "Solar Panel Installation",
    remarks2: "",
    subremarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssItem: "",
    cssTitle2: "color-primary",

    cost: "₱250 per hour",
    address: "San Jose, Antipolo, Philippines",
    latlong: "",
  },
];

const TabBrowse: React.FC<any> = () => {
  const [searchText, setSearchText] = useState<string>("");
  const onClickItem = (id: number) => {
    alert("onClickItem id: " + id);
  };
  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <AtSearchBar setSearchText={setSearchText} />
        <IonCard color="none" at-default>
          <IonCardContent>
            <AtListItem
              data={data}
              isMergedLine2={false}
              onClickItem={onClickItem}
              isTextWrap={true}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
      {console.log("searchText>>>", searchText)}
    </>
  );
};

export default TabBrowse;
