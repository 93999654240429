import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonLabel, 
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css"; 
import TaskBidding from "./modals/tasks/TaskBidding";

import TaskBooking from "./modals/tasks/TaskBooking";
import TaskHistory from "./modals/tasks/TaskHistory";

const TabTasks: React.FC<any> = () => {
  const tabNames = ["Bidding", "Booking", "History"];
  const [tabName, setTabName] = useState<any>("booking");
  const [mySwiper, setMySwiper] = useState<any>();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const m = urlParams.get("m"); 
    if (m) {
      setTabName(m);
    }
  }, [window.location.search]);

  const onChangeTabName = (type: any) => {
    const slideNo = tabNames.findIndex(
      (tab: any) => tab.toLowerCase() === type
    );
    mySwiper.slideTo(slideNo);
  };

  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonSegment
          value={tabName}
          onIonChange={(e) => onChangeTabName(e.detail.value)}
        >
          <IonSegmentButton value="bidding">
            <IonLabel
              style={{ textTransform: "none" }}
              className="at-bold size-16"
            >
              Bidding
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="booking">
            <IonLabel
              style={{ textTransform: "none" }}
              className="at-bold size-16"
            >
              Booking
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="history">
            <IonLabel
              style={{ textTransform: "none" }}
              className="at-bold size-16"
            >
              History
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <Swiper
          onInit={(e: any) => setMySwiper(e)}
          onSlideChange={(e: any) =>
            setTabName(tabNames[e.activeIndex].toLowerCase())
          }
          initialSlide={tabNames.findIndex(
            (tab) => tab.toLowerCase() === tabName
          )}
        >
          <SwiperSlide className="min-height-80vh cursor-grab">
            <TaskBidding />
          </SwiperSlide>
          <SwiperSlide className="min-height-80vh cursor-grab">
            <TaskBooking />
          </SwiperSlide>
          <SwiperSlide className="min-height-80vh cursor-grab">
            <TaskHistory />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </>
  );
};

export default TabTasks;
