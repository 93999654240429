import { IonItem, IonLabel, IonCardTitle, IonCardSubtitle } from "@ionic/react";
import React, { useState } from "react";

const AtItemDetails: React.FC<any> = ({
  data,
  isMergedLine2 = false,
  isTextWrap = false,
  isShowMore = false,
  textWrapRows = 1,
}) => {
  const ellipsis = `at-text-wrap${textWrapRows > 1 ? "-" + textWrapRows : ""}`;
  const [showMoreText, setShowMoreText] = useState<string>(ellipsis);

  const showMoreButton = () => {
    return (
      <button
        className="at-size-12 show-more"
        onClick={() => setShowMoreText(showMoreText ? "" : ellipsis)}
      >
        ...show {showMoreText ? "more" : "less"}
      </button>
    );
  };
  return (
    <>
      {" "}
      <div className="at-item-list">
        <img
          alt=""
          className="task-image"
          src={`/assets/images/${data.photo}`}
          width="60"
        ></img>
        <IonItem lines="none" className={`${data?.cssItem} `}>
          <IonLabel position="stacked" slot="start"></IonLabel>
          {data.title && (
            <IonCardTitle className="at-size-16">
              <span className={`column ${isTextWrap ? "at-text-wrap" : ""}`}>
                {data.title}
              </span>
              <span
                className={`${data?.title2 ? "at-right" : ""} 
            ${data?.cssTitle2}`}
              >
                {data.title2}
              </span>
            </IonCardTitle>
          )}
          {data.subtitle && (
            <IonCardSubtitle className="at-size-12 at-margin-5-top">
              <span
                className={`${isTextWrap ? "at-text-wrap" : ""} ${`${
                  isMergedLine2 ? "" : "column"
                }`}`}
              >
                {data.subtitle}
                &nbsp; {`${isMergedLine2 ? "|" : ""}`} &nbsp;
              </span>
              <span
              // className={`${isMergedLine2 ? "" : "column-2 at-right"}`}
              >
                {data.subtitle2}
              </span>
            </IonCardSubtitle>
          )}
          {/* {data.remarks && <div className="at-margin-5-top"> <p>{data.remarks}</p></div>} */}
          {/* {data.remarks &&  <p>{data.remarks}</p> } */}
          {data.remarks && (
            <IonCardSubtitle color="primary">
              <p className={`column ${isTextWrap ? "at-text-wrap" : ""}`}>
                {data.remarks}
              </p>
              {data.remarks2 && <p className="at-right">{data.remarks2}</p>}
              {/* {isChatEnable && (
            <>
               <div className="column-2 at-right at-size-24 at-pad-10-left">
                <IonIcon
                  icon={chatbubbleEllipses}
                  color="primary"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    alert("chat");
                  }}
                ></IonIcon>
              </div>  
                <div className=" at-size-24 at-pad-10-left">
                <IonIcon
                  icon={call}
                  color="primary"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open("tel:+971503908526")
                
                  }}
                ></IonIcon>
              </div>  
            </>
          )} */}
            </IonCardSubtitle>
          )}
          {data.subremarks && (
            <IonCardSubtitle className="at-margin-10-tb ">
              <p className={`${isTextWrap ? showMoreText : ""}`}>
                {data.subremarks} 
                {isShowMore && showMoreButton()}
              </p>
            </IonCardSubtitle>
          )}
        </IonItem>
      </div>
    </>
  );
};

export default AtItemDetails;
