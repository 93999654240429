import React from "react";
import { IonContent, IonCard, IonCardContent } from "@ionic/react";
import AtListItem from "../common/AtListItem";
import { useHistory } from "react-router";
// import { createOutline } from "ionicons/icons";

const data = [
  {
    id: 1,
    photo: "randy_legaspi.jpg",
    remarks: "Randy Legaspi confirmed service booking for Bathroom Fittings",
    subremarks: "5 minutes ago",
    urlParam: "/tasks?m=booking",
  },
  {
    id: 2,
    photo: "geraldine_tividad.jpg",
    remarks: "Geraldine Tividad sent an offer for Bathroom Fittings",
    subremarks: "23 hours ago",
    urlParam: "/tasks?m=bidding",
  },
  {
    id: 3,
    photo: "auric_gavin.jpg",
    remarks: "Auric Gavin completed the task for Solar Panel Installation",
    subremarks: "25 October, 10:25 am",
    urlParam: "/tasks?m=history",
  },
];

const TabNotifications: React.FC<any> = () => {
  const history = useHistory();

  const onClickItem = (id: number) => {
    const dat: any = data.find((d: any) => d.id === id);
    // alert(dat?.urlParam)
    history.push(dat?.urlParam); 
    // history.push({
    //   pathname: "/tasks",
    //   state: { detail: {m:"booking"} }
    // });
  };
  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardContent>
            <AtListItem
              data={data}
              isMergedLine2={true}
              onClickItem={onClickItem}
            />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
};

export default TabNotifications;
