import React, { useEffect, useState } from "react";
import {
  IonLabel,
  IonContent,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonCardContent,
  IonList,
  IonProgressBar,
} from "@ionic/react";
import AtListItem from "../common/AtListItem";
import { star } from "ionicons/icons";

const data = [
  {
    photo: "randy_legaspi.jpg",
    title: "Randy Legaspi",
    title2: (
      <>
        4.5{" "}
        <IonIcon icon={star} style={{ fontSize: "12px", fontWeight: "bold" }} />
      </>
    ),
    subtitle: "Interior Decorations",
    subtitle2: "19 Dec, 11:30 am",
    remarks:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Distinctio fugit maxime id nemo alias quam delectus nesciunt nobis doloribus at, sit velit eligendi quisquam aut nulla voluptates rem! Ullam, aliquam?",
    cssTitle2: "color-primary",
  },
  {
    photo: "geraldine_tividad.jpg",
    title: "Geraldine Tividad",
    title2: (
      <>
        4.0{" "}
        <IonIcon icon={star} style={{ fontSize: "12px", fontWeight: "bold" }} />
      </>
    ),
    subtitle: "Bathroom fittings",
    subtitle2: "28 Feb, 10:25 am",
    remarks:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio maiores consectetur, dignissimos optio accusantium error a assumenda velit. Vero quidem architecto debitis quis quasi fuga totam sed nisi sequi soluta!",
    cssTitle2: "color-primary",
  },
  {
    photo: "auric_gavin.jpg",
    title: "Auric Gavin",
    title2: (
      <>
        3.9{" "}
        <IonIcon icon={star} style={{ fontSize: "12px", fontWeight: "bold" }} />
      </>
    ),
    subtitle: "Solar Panel Installation",
    subtitle2: "28 Feb, 10:25 am",
    remarks: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. ",
    cssTitle2: "color-primary",
  },
];

const TabRatings: React.FC<any> = () => {
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [rateAverage, setRateAverage] = useState<number>(0);
  const ratings: any = [250, 160, 90, 15, 10];

  useEffect(() => {
    //--star rating formula>>>>  AR = 1*a+2*b+3*c+4*d+5*e/(R)
    let sum: number = 0,
      total = 0;
    ratings.map((rating: any, index: number) => {
      sum += rating;
      total += rating * (5 - index);
      return sum;
    });
    setCustomerCount(sum);
    setRateAverage(total / sum);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <IonContent className="ion-padding " scrollEvents={true}>
        <IonCard color="none" at-default>
          <IonCardHeader className="at-center ">
            <IonCardTitle
              className="color-primary"
              style={{ fontSize: "36px", fontWeight: "bold" }}
            >
              {rateAverage.toFixed(1)}
              <IonIcon
                icon={star}
                style={{ fontSize: "24px", fontWeight: "bold" }}
              />
            </IonCardTitle>
            <IonCardSubtitle>
              Average rating by {customerCount} customers
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
        <IonCard>
          <IonCardContent>
            <IonList>
              {ratings &&
                ratings?.map((rating: any, index: any) => {
                  return (
                    <IonItem
                      key={index}
                      lines="none"
                      style={{ height: "36px" }}
                    >
                      <IonLabel slot="start" className="at-unflex">
                        {5 - index}.0 &nbsp;
                        <IonIcon icon={star} className="at-size-12" />
                      </IonLabel>
                      <IonProgressBar
                        value={rating / customerCount}
                      ></IonProgressBar>
                      <IonLabel slot="end">{rating}</IonLabel>
                    </IonItem>
                  );
                })}
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard color="none" at-default>
          <IonCardHeader>
            <IonCardTitle>Recent Ratings</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <AtListItem data={data} isMergedLine2={true} isReadOnly={true} />
          </IonCardContent>
        </IonCard>
      </IonContent>
    </>
  );
};

export default TabRatings;
