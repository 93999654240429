import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  arrowBackOutline,
  phonePortraitOutline,
  person,
  mail,
  informationCircle,
} from "ionicons/icons";
import useAuth from "../../../../hooks/useAuth";

const categories = [
  "Air Conditioning",
  "Building Maintenance",
  "Carpentry",
  "Electrical",
  "Home Improvement",
  "Painting",
  "Pest Control",
  "Plumbing",
  "Roofing",
  "Smart Product Installation",
  "Solar Panel Installation",
  "Tiling",
];

const ModalAccount: React.FC<any> = ({
  isOpen,
  onClose,
  // setResult
}) => {
  const { auth } = useAuth();
  const [state, setState] = useState<any>({
    email: "avtividad@yahoo.com",
    name: "Adonis Tividad",
    mobile_no: "+91771794521",
    categories: ["Electrical", "Carpentry"],
    services: "",
    documents: [],
    about:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem aliquid veniam dignissimos nulla. Quo, quisquam inventore. At earum debitis iusto, laudantium adipisci eius optio dolores nobis, numquam quae sint ducimus?",
  });
  useEffect(() => {
    const { name, imageUrl } = auth;
    setState({ ...state, name, imageUrl });
  }, []);

  const onDismiss = () => {
    // setResult({ lat: center[0], lon: center[1], name: placeName });
    onClose(1);
  };
  const saveInfo = () => {
    alert("Info updated \n" + JSON.stringify(state));
    onClose(1);
  };
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss} at-default>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={arrowBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>My Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding pad-60-bottom">
        <IonCard color="none">
          <IonCardContent>
            <IonItem
              className="pad-20-bottom cursor-pointer"
              color="none"
              lines="none"
            >
              <img
                alt=""
                className="task-image"
                // slot="start"
                src={state?.imageUrl}
                width="120"
              ></img>
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Mobile Number</IonLabel>
              <IonIcon
                color="primary"
                icon={phonePortraitOutline}
                size="small"
                slot="start"
              />
              <IonInput
                value={state.mobile_no}
                // onFocus={(e) => setState({ ...state, mobile_no: "" })}
                onIonChange={(e) =>
                  setState({ ...state, mobile_no: e.detail.value! })
                }
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">Full Name</IonLabel>
              <IonIcon
                color="primary"
                icon={person}
                size="small"
                slot="start"
              />
              <IonInput
                value={state.name}
                // onFocus={(e) => setState({ ...state, name: "" })}
                onIonChange={(e) =>
                  setState({ ...state, name: e.detail.value! })
                }
              />
            </IonItem>{" "}
            <IonItem color="none">
              <IonLabel position="floating">Email Address</IonLabel>
              <IonIcon color="primary" icon={mail} size="small" slot="start" />
              <IonInput
                value={state.email}
                // onFocus={(e) => setState({ ...state, email: "" })}
                onIonChange={(e) =>
                  setState({ ...state, email: e.detail.value! })
                }
                readonly={true}
                disabled={true}
              />
            </IonItem>
            <IonItem color="none">
              <IonLabel position="floating">About</IonLabel>
              <IonIcon
                color="primary"
                icon={informationCircle}
                size="small"
                slot="start"
              />
              <IonTextarea
                value={state.about}
                // onFocus={(e) => setState({ ...state, about: "" })}
                onIonChange={(e) =>
                  setState({ ...state, about: e.detail.value! })
                }
                rows={3}
              />
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>Your Services</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <IonItem color="none">
              <IonLabel
                position="floating"
                className={`${
                  state.categories.length > 0 ? "ion-select-label" : ""
                }`}
              >
                Select job category
              </IonLabel>
              <IonSelect
                multiple={true}
                value={state.categories}
                onIonChange={(e) =>
                  setState({ ...state, categories: e.detail.value! })
                }
              >
                {categories &&
                  categories.map((option, i) => (
                    <IonSelectOption value={option} key={i}>
                      {option}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          </IonCardContent>
        </IonCard>
        {/* <IonCard color="none">
          <IonCardHeader>
            <IonCardTitle>Document Verification</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
             
            <IonItem color="none">
              <IonLabel position="floating">Select job category</IonLabel>
              <IonInput
                value={state.category}
                onFocus={(e) => setState({ ...state, category: "" })}
                onIonChange={(e) =>
                  setState({ ...state, category: e.detail.value! })
                }
              />
            </IonItem>
          </IonCardContent>
        </IonCard> */}
      </IonContent>
      <div className="at-menu-bottom">
        <IonButton expand="block" onClick={saveInfo}>
          Update Info
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ModalAccount;
